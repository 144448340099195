import { BaseModel } from 'modules/core/models/base-model';

const PLANNED                        = 1;
const AWAITING_PAYMENT               = 2;
const PAID                           = 3;
const AWAITING_SCHEDULING            = 4;
const AWAITING_SCHEDULE_CONFIRMATION = 5;
const SCHEDULED                      = 6;
const EXECUTED                       = 7;
const CANCELED                       = 8;

export class ProcedureStatus extends BaseModel {

    static get PLANNED() {
        return PLANNED;
    }

    static get AWAITING_PAYMENT() {
        return AWAITING_PAYMENT;
    }

    static get PAID() {
        return PAID;
    }

    static get AWAITING_SCHEDULING() {
        return AWAITING_SCHEDULING;
    }

    static get AWAITING_SCHEDULE_CONFIRMATION() {
        return AWAITING_SCHEDULE_CONFIRMATION;
    }

    static get SCHEDULED() {
        return SCHEDULED;
    }

    static get EXECUTED() {
        return EXECUTED;
    }

    static get CANCELED() {
        return CANCELED;
    }

}
