import { BaseModel } from 'modules/core/models/base-model';

const APPROVED                    = 1;
const APPROVED_WITH_CLAUSES       = 2;
const REJECTED_WITH_C2_CLAUSES    = 3;
const SEALED_WITH_C1_CLAUSES      = 4;
const MALFUNCTION                 = 5;
const MISSING_MAINTENANCE_COMPANY = 6;
const NO_ACCESS                   = 7;

export class ProcedureResult extends BaseModel {

    static get APPROVED() {
        return APPROVED;
    }

    static get APPROVED_WITH_CLAUSES() {
        return APPROVED_WITH_CLAUSES;
    }

    static get REJECTED_WITH_C2_CLAUSES() {
        return REJECTED_WITH_C2_CLAUSES;
    }

    static get SEALED_WITH_C1_CLAUSES() {
        return SEALED_WITH_C1_CLAUSES;
    }

    static get MALFUNCTION() {
        return MALFUNCTION;
    }

    static get MISSING_MAINTENANCE_COMPANY() {
        return MISSING_MAINTENANCE_COMPANY;
    }

    static get NO_ACCESS() {
        return NO_ACCESS;
    }

}
