import { BaseModel } from 'modules/core/models/base-model';
import moment        from 'moment';

export class ProcedureExecution extends BaseModel {

    inspector_id              = null;
    maintenance_technician_id = null;
    scheduled_date            = null;
    execution_date            = null;
    started_at                = null;
    ended_at                  = null;
    result_id                 = null;
    observations              = null;
    report_id                 = null;
    next_execution_deadline   = null;
    next_request_deadline     = null;

    /**
     * Calculates the execution and request deadline if a new certificate is emitted from this inspection
     *
     * @param settings
     */
    calculateCertificateDeadlines(settings) {
        this.next_execution_deadline = null;
        this.next_request_deadline   = null;

        if (this.execution_date) {
            const daysBeforeDeadline = settings.inspection_request_notification - settings.inspection_request_deadline;
            const from               = this.previous_execution_date ? moment(this.previous_execution_date) : moment(this.execution_date);

            this.next_execution_deadline = moment(from).add(this.lift.inspection_periodicity, 'years');
            this.next_request_deadline   = moment(from).add(this.lift.inspection_periodicity, 'years').subtract(daysBeforeDeadline, 'days');
        }
    }

}
